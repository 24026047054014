import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref } from "vue";
import BasisGroup from "@/views/basisGroup/index.vue";
import Nav from "@/components/nav/nav.vue";
import { jobsApi } from "@/api/jobs/index";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
export default defineComponent({
    components: { BasisGroup: BasisGroup, Nav: Nav, HomeJumbotron: HomeJumbotron },
    setup: function () {
        var _this = this;
        document.documentElement.scrollTop = 0;
        var navActiveIndex = ref("3-2");
        var jobsList = ref("");
        var jobItem = ref("");
        var getJobsList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = jobsList;
                        return [4 /*yield*/, jobsApi({
                                currentPage: 1,
                                size: 5,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        jobItem.value = jobsList.value.records[0];
                        return [2 /*return*/];
                }
            });
        }); };
        var selectJob = function (id) {
            jobItem.value = jobsList.value.records.find(function (item) {
                return item["id"] === id;
            });
        };
        onMounted(function () {
            getJobsList();
        });
        return {
            selectJob: selectJob,
            jobsList: jobsList,
            jobItem: jobItem,
            navActiveIndex: navActiveIndex,
        };
    },
});
